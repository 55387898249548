import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import { setupFollowModal } from "../modules/setup_follow_modal"
import { setupBrokerModal } from "../modules/setup_broker_modal"
import { setupPremiseMap } from "../modules/setup_premise_map"
import { setupFeeSlider } from "../modules/setup_fee_slider"
import { setupFeeChart } from "../modules/setup_fee_chart"
import { setupGenderChart } from "../modules/setup_gender_chart"
import {
  setupBrokerCarousel,
  setupCarousel,
  setupPhotoCarousel,
  setupPlusHeader,
} from "../modules/setup_carousel"
import { setupDocumentFiltering } from "../modules/setup_document_filtering"
import { setupApartmentsChart } from "../../../../shared_assets/app/webpack_assets/modules/setup_apartments_chart"
import { setupNeighborData } from "../../../../shared_assets/app/webpack_assets/modules/setup_neighbor_data"
import { updateSectionUrl } from "../modules/update_section_url"
import { scrollTo } from "../modules/setup_scroll"
import { setupModal } from "../../../../shared_assets/app/webpack_assets/shared/setup_modal"

import "intersection-observer"
import scrollama from "scrollama"

export default class extends ApplicationController {
  static targets = [
    "mapBtn",
    "placeholderMap",
    "infoOrgButton",
    "infoApartmentsButton",
    "informationsParentContainer",
    "informationsContainer",
    "apartmentsContainer",
  ]

  connect() {
    history.scrollRestoration = "manual"

    this._setupOrganizationNavbar()
    setupFollowModal()
    setupPlusHeader()

    this._initGoogleCharts()

    this._lazyLoad()
    this._observeWaypoints()
  }

  _lazyLoad() {
    const sections = document.querySelectorAll("section.unloaded")
    sections.forEach((section) => {
      if (section.classList.contains("unloaded")) {
        if (section.children.length) {
          this._initSectionScripts(section)
        } else {
          this.railsFetch(section.getAttribute("data-path")).then((value) => {
            section.insertAdjacentHTML("afterbegin", value.html)
            this._initSectionScripts(section)
          })
        }

        section.classList.remove("unloaded")
      }
    })
  }

  _observeWaypoints() {
    const scroller = scrollama()
    scroller
      .setup({
        step: "section.waypoint",
      })
      .onStepEnter((response) => {
        updateSectionUrl(response.element.getAttribute("data-name"))
        this._highlightMenu()
      })
  }

  displayOrganizationInfo(event) {
    event.preventDefault()

    this.informationsContainerTarget.classList.add("informations--fullview")
    this.informationsParentContainerTarget.classList.remove(
      "informationsgradient"
    )

    this._fadeOut(this.infoOrgButtonTarget)
  }

  displayApartments(event) {
    event.preventDefault()

    this.apartmentsContainerTarget.classList.add("apartments--fullview")
    this.apartmentsContainerTarget.classList.remove("apartmentsgradient")

    this._fadeOut(this.infoApartmentsButtonTarget)
  }

  scrollToSection(e) {
    e.preventDefault()

    const href = e.target.getAttribute("href")
    const target = document.querySelector(href)

    if (target === null) {
      return
    }

    const path = e.target.getAttribute("data-path")

    scrollTo(target)
    updateSectionUrl(path)
  }

  activateTenantTab() {
    document.querySelector("#tenant-anchor").click()
  }

  onClickLoadMap() {
    document.querySelector("#premise-map2").classList.remove("hidden")
    setupPremiseMap("premise-map2")
    this.mapBtnTarget.remove()
    this.placeholderMapTarget.remove()
  }

  enableMapScroll() {
    if (!window.mapboxContainer) {
      return
    }

    setTimeout(() => {
      window.mapboxContainer.dragging.enable()
      window.mapboxContainer.touchZoom.enable()
      window.mapboxContainer.doubleClickZoom.enable()
      window.mapboxContainer.scrollWheelZoom.enable()
    }, 1000)
  }

  disableMapScroll() {
    if (!window.mapboxContainer) {
      return
    }

    window.mapboxContainer.dragging.disable()
    window.mapboxContainer.touchZoom.disable()
    window.mapboxContainer.doubleClickZoom.disable()
    window.mapboxContainer.scrollWheelZoom.disable()
  }

  activateDescriptionTabMobile(e) {
    e.currentTarget.parentNode.classList.toggle("__active")
  }

  activateDescriptionTab(e) {
    e.preventDefault()

    const tabsContainer = e.currentTarget.closest(".descriptions--container")

    tabsContainer.querySelectorAll(".tab").forEach((tab) => {
      tab.classList.add("hidden")
    })

    tabsContainer.querySelectorAll("li").forEach((li) => {
      li.classList.remove("__active")
    })

    e.currentTarget.classList.add("__active")

    const tabId = e.currentTarget
      .querySelector("a")
      .getAttribute("data-href-target")
    document.getElementById(tabId).classList.remove("hidden")
  }

  _initSectionScripts(section) {
    for (const script of section.getElementsByTagName("script")) {
      eval(script.innerText)
    }

    switch (section.getAttribute("data-name")) {
      case "ekonomi":
        setupFeeChart()
        setupFeeSlider()
        break
      case "info_bilder":
        setupModal("authentication-modal")
        this._setupInformationsImagesModal()
        break
      case "forsaljningar":
        setupCarousel()
        setupBrokerCarousel()
        setupPhotoCarousel()
        setupBrokerModal()
        break
      case "lagenheter":
        setupApartmentsChart()
        break
      case "omradet":
        setupCarousel()
        setupNeighborData()
        this.disableMapScroll()
        break
      case "styrelse":
        setupGenderChart()
        break
      case "dokument":
        setupModal("authentication-modal")
        setupDocumentFiltering()
        break
    }
  }

  _initGoogleCharts() {
    google.charts.load("current", {
      packages: ["corechart", "bar", "table"],
      language: "sv",
    })
  }

  _fadeOut(element) {
    element.style.transition = "0.7s"
    element.style.opacity = 0
  }

  _setupInformationsImagesModal() {
    setupModal("informations-images-modal")

    const imagePlaceholder = document.getElementById(
      "informations-images-image"
    )

    document
      .querySelectorAll(".informations-images-modal__button")
      .forEach((button) => {
        button.addEventListener("click", (event) => {
          const img = document.createElement("img")
          img.src = event.currentTarget.getAttribute("data-url")

          imagePlaceholder.innerHTML = ""
          imagePlaceholder.appendChild(img)
        })
      })
  }

  _highlightMenu() {
    document.querySelectorAll("header .menu--submenu li a").forEach((tab) => {
      tab.parentNode.classList.remove("active")
      const path = tab.getAttribute("data-path")

      if (window.location.pathname.includes(path)) {
        tab.parentNode.classList.add("active")
      }
    })
  }

  _setupOrganizationNavbar = () => {
    document
      .querySelectorAll("header .menu--submenu a")
      .forEach((tab, index) => {
        const path = tab.getAttribute("data-path")
        const section = document.querySelector(`section[data-name='${path}']`)

        if (tab.getAttribute("data-type") && section) {
          tab.addEventListener("click", (e) => {
            e.preventDefault()

            scrollTo(section)
            updateSectionUrl(path)

            this._highlightMenu()
          })
        }
      })
  }
}
