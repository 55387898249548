import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  calculateTotalPages,
  dateFormatter,
  minMaxFilterEditor,
  minMaxFilterFunction,
  presenceFilterFunction,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import * as XLSX from "xlsx"

export default class extends ApplicationController {
  static targets = ["modalPocket", "modalDialog"]

  connect() {
    this._initializeTabulator()
  }

  _initializeTabulator() {
    const table = new Tabulator("#payout-requests-table", {
      layout: "fitData",
      layoutColumnsOnNewData: true,
      ajaxURL: this.data.get("api-url"),
      dataSendParams: { size: "page_size" },
      ajaxResponse: (_url, _params, response) => {
        this.table_rowCount = response["meta"].total
        return this._transformResponse(response)
      },
      width: "100%",
      pagination: true,
      paginationMode: "remote",
      paginationSize: 25,
      paginationSizeSelector: [25, 50, 100],
      columns: [
        {
          title: "Datum",
          field: "created_at",
          formatter: dateFormatter,
          frozen: true,
          headerFilter: "input",
        },
        {
          title: "Typ",
          field: "payout_type",
          headerFilter: "list",
          headerFilterParams: {
            valuesLookup: true,
            clearable: true,
          },
          hozAlign: "center",
          width: "13%",
        },
        {
          title: "Skapad av",
          field: "creator_name",
          headerFilter: "input",
          width: "17%",
        },
        {
          title: "Mottagare",
          field: "recipient_name",
          headerFilter: "input",
          width: "17%",
        },
        {
          title: "Belopp (ex moms)",
          field: "amount",
          hozAlign: "right",
          formatter: "money",
          formatterParams: {
            precision: false,
            thousand: " ",
          },
          headerFilter: minMaxFilterEditor,
          headerFilterFunc: minMaxFilterFunction,
          headerFilterLiveFilter: false,
          width: "18%",
        },
        {
          title: "Status",
          field: "status",
          formatter: (cell, _formattedParams, _onRendered) => {
            switch (cell.getValue()) {
              case "pending":
                return "<span class='pending'></span><span>Inväntar attest</span>"
              case "approved":
                return "<span class='approved'></span><span>Attesterad</span>"
              case "rejected":
                return "<span class='rejected'></span><span>Avvisad</span>"
              case "processing":
                return "<span class='processing'></span><span>Bearbetning</span>"
              case "failed":
                return "<span class='failed'></span><span>Misslyckad</span>"
              case "completed":
                return "<span class='completed'></span><span>Slutförd</span>"
              default:
                return cell.getValue()
            }
          },
          frozen: true,
          headerFilter: "list",
          headerFilterParams: {
            values: {
              pending: "Inväntar attest",
              approved: "Attesterad",
              rejected: "Avvisad",
              processing: "Bearbetning",
              failed: "Misslyckad",
              completed: "Slutförd",
            },
            clearable: true,
          },
        },
        {
          title: "Dokument",
          field: "document_url",
          download: false,
          headerSort: false,
          formatter: (cell, _formattedParams, _onRendered) => {
            if (cell.getValue() !== null) {
              return `<a href='${cell.getValue()}' target='_blank'><span class='payout-requests__document'></span></a>`
            }
          },
          headerFilter: "tickCross",
          headerFilterParams: {
            tristate: true,
          },
          headerFilterFunc: presenceFilterFunction,
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })

    table.on("rowClick", (e, row) => {
      // NOTE: Make sure the clicked cell doesn't contain any links,
      //   otherwise abort the handler. Check if the anchor element or
      //   its descendants were clicked.
      if (e.target.nodeName === "A" || e.target.closest("a")) return

      const data = row.getData()
      this._showDialog(e, data.api_path)
    })

    this.payoutRequestsTable = table
    window.XLSX = XLSX
  }

  exportTable(event) {
    event.preventDefault()

    let table = this.payoutRequestsTable
    let page = table.getPage()
    let pageSize = table.getPageSize()
    table.setPageSize(this.table_rowCount)
    table.setPage(1).then(function () {
      table.download("xlsx", "Utbetalningar.xlsx", {
        sheetName: "Utbetalningar",
      })
      table.clearData()
      table.setPageSize(pageSize)
      table.setPage(page)
      table.restoreRedraw()
    })
  }

  _transformResponse(response) {
    const pageSize = this.payoutRequestsTable.getPageSize()
    const arr = []

    response.data.forEach((row) => {
      arr.push(row.attributes)
    })

    return { last_page: calculateTotalPages(response, pageSize), data: arr }
  }

  _showDialog(e, apiPath) {
    e.preventDefault()

    this.railsFetch(apiPath).then((value) => {
      this.modalPocketTarget.innerHTML = value.html
      setupModal("payout-request-modal")
      this.modalDialogTarget.classList.toggle("falldown")
    })
  }

  closeDialog(e) {
    e.preventDefault()
    this.modalDialogTarget.classList.toggle("falldown")
  }
}
