import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import { tabulatorSV } from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import DoublePicker from "../modules/register/register_double_picker"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import * as Sentry from "@sentry/browser"
import * as XLSX from "xlsx"

export default class extends ApplicationController {
  static targets = [
    "fromDate",
    "toDate",
    "failedToLoad",
    "isLoading",
    "switch",
    "export",
  ]

  connect() {
    this.fromDateTarget.value = this.data.get("custom-start")
    this.toDateTarget.value = this._toLocateDateString(new Date())
    this.switchEnabled = false
    const financialYearsRawData = this.data.get("financial-years")
    const minBudgetYear = this.data.get("min-budget-year")
    const maxBudgetYear = this.data.get("max-budget-year")

    this.doublePicker = new DoublePicker(
      [this.fromDateTarget, this.toDateTarget],
      JSON.parse(financialYearsRawData),
      [minBudgetYear, maxBudgetYear],
      () => this._yearApiData()
    )

    this._initializeTabulator()
  }

  toggleMode() {
    this.switchTarget.classList.toggle("active")

    this.switchEnabled = !this.switchEnabled
    const start = this.fromDateTarget.value
    const end = this.toDateTarget.value
    const url =
      this.data.get("api-url") +
      `?from=${start}` +
      `&to=${end}` +
      `&advanced=${this.switchEnabled}`

    if (this.switchEnabled) {
      this.exportTarget.classList.remove("hidden")
    } else {
      this.exportTarget.classList.add("hidden")
    }

    this.table.replaceData([])
    this.isLoadingTarget.classList.remove("hidden")

    const response = this.railsFetch(url)

    this._filterHandler(response)
  }

  _yearApiData() {
    try {
      const start = this.fromDateTarget.value
      const end = this.toDateTarget.value
      const url =
        this.data.get("api-url") +
        `?from=${start}` +
        `&to=${end}` +
        `&advanced=${this.switchEnabled}`

      this.table.replaceData([])
      this.isLoadingTarget.classList.remove("hidden")

      const response = this.railsFetch(url)

      this._filterHandler(response)
    } catch (error) {
      Sentry.captureException(error)
      this.isLoadingTarget.classList.add("hidden")
      this.failedToLoadTarget.classList.remove("hidden")
    }
  }

  _filterHandler(response) {
    response.then((value) => {
      try {
        this.table.replaceData(value.data)
        this.isLoadingTarget.classList.add("hidden")
      } catch (error) {
        Sentry.captureException(error)
      }
    })
  }

  _toLocateDateString(date) {
    return date.toLocaleDateString("sv-se")
  }

  _periodFinancialYearStart(date) {
    const financialYear = this.doublePicker.getFinancialYearFor(date)
    return this._toLocateDateString(financialYear.start)
  }

  _initializeTabulator() {
    const previewUrl = this.data.get("preview-url")

    const startFinancialYear = this._periodFinancialYearStart(
      new Date(this.fromDateTarget.value)
    )
    const apiUrl = `${this.data.get("api-url")}?from=${startFinancialYear}&to=${
      this.toDateTarget.value
    }`
    const changeTxtColor = (value, cell) => {
      if (value == "0" && !cell.getData().name.match(/Resultat/)) {
        cell.getElement().style.color = "rgba(17, 17, 17, 0.4)"
      }
    }

    this.railsFetch(apiUrl).then((response) => {
      this.dataTable = response.data

      var table = new Tabulator("#finance-results", {
        height: "100%",
        layout: this._windowInnerWidth(),
        layoutColumnsOnNewData: true,
        dataTree: true,
        data: this.dataTable,
        dataTreeChildIndent: 0,
        dataTreeBranchElement: false,
        dataTreeCollapseElement: "<span class='icon-minus'></span>",
        dataTreeExpandElement: "<span class='icon-plus'></span>",
        dataTreeStartExpanded: function (row, level) {
          return row.getData().mode
        },
        rowFormatter: function (row) {
          const data = row.getData()

          if (
            data.current_amount == 0 &&
            data.past_amount == 0 &&
            data.budget == 0
          ) {
            row.getElement().classList.add("hidden")
          }
        },
        columns: [
          {
            title: this._nameHeader(),
            field: "name",
            responsive: 0,
            headerSort: false,
            hozAlign: "left",
            widthGrow: 1.6,
            formatter: function (cell) {
              const data = cell.getData()
              const row = cell.getRow()
              const url = previewUrl.replace(/\/[0]/, "/" + `${data.id}`)

              if (row.getTreeParent() === false) {
                cell.getElement().style.fontWeight = 900
              }

              data.link
                ? (data.name = `<div class="file-link"><a href=${url} target="_blank" rel="noopener noreferrer"><u>${data.name}</u></a></div>`)
                : data.name

              return `${data.name}`
            },
          },
          {
            title: "Period",
            field: "current_amount",
            headerSort: false,
            hozAlign: "left",
            cssClass: "leftAlignedHeader",
            widthGrow: 0.7,
            formatter: function (cell, _formatterParams) {
              const currentAmount = cell.getData().current_amount

              changeTxtColor(currentAmount, cell)

              return Math.round(currentAmount).toLocaleString("sv-se", {
                useGrouping: true,
              })
            },
          },
          {
            title: "Period fg år",
            field: "past_amount",
            responsive: 2,
            headerSort: false,
            hozAlign: "left",
            cssClass: "leftAlignedHeader",
            widthGrow: 0.7,
            formatter: function (cell) {
              const past_amount = cell.getData().past_amount

              changeTxtColor(past_amount, cell)

              return Math.round(past_amount).toLocaleString("sv-se", {
                useGrouping: true,
              })
            },
          },
          {
            title: "Periodens budget",
            field: "budget",
            headerSort: false,
            hozAlign: "left",
            cssClass: "leftAlignedHeader",
            widthGrow: 0.7,
            formatter: function (cell) {
              const data = cell.getData()
              if (data.link) return ""

              const budget = data.budget
              changeTxtColor(budget, cell)
              return Math.round(budget).toLocaleString("sv-se", {
                useGrouping: true,
              })
            },
          },
          {
            title: "Ackumulerat",
            field: "total",
            headerSort: false,
            hozAlign: "left  ",
            cssClass: "leftAlignedHeader",
            widthGrow: 0.7,
            formatter: function (cell) {
              const data = cell.getData()
              const row = cell.getRow()
              const total = data.total

              changeTxtColor(total, cell)
              if (data.name.match(/Resultat/)) {
                row.getElement().style.fontWeight = 900
              } else if (row.getTreeParent() === false) {
                cell.getElement().style.fontWeight = 900
              }

              return Math.round(total).toLocaleString("sv-se", {
                useGrouping: true,
              })
            },
          },
        ],
        locale: "sv",
        langs: tabulatorSV(),
      })

      this.table = table
      window.XLSX = XLSX
    })
  }

  _nameHeader() {
    return `
      <div class='main-switch' data-action="click->board-results#toggleMode" data-target="board-results.switch">
        <label for='switch'>
          Detaljerad vy
        </label>
        <input type='checkbox' name='switch' id='switch'>
        <span class='checkbox'></span>
      </div>
    `
  }

  exportTable(event) {
    event.preventDefault()

    this.table.download("xlsx", "Resultatrapport.xlsx", {
      documentProcessing: function (workbook) {
        var sheet = workbook.Sheets.Sheet1

        XLSX.utils.sheet_add_aoa(
          sheet,
          [
            [
              "Namn",
              "Period",
              "Period fg år",
              "Periodens budget",
              "Ackumulerat",
            ],
          ],
          {
            origin: 0,
          }
        )

        return workbook
      },
    })
  }

  _windowInnerWidth() {
    const windowWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    if (windowWidth >= 768) {
      return "fitColumns"
    } else {
      return "fitDataFill"
    }
  }
}
