export function setupCarousel() {
  $.each($(".carousel"), function (i, slider) {
    var sliderItem = $(slider).find(".carousel__item")
    var pageContainer = $(".app__container")

    if ($(slider).hasClass("slick-initialized")) {
      if (slidesWithinBoundaries(sliderItem, pageContainer)) {
        $(slider).slick("unslick")
        $(slider).addClass("slider-disabled")
      }
    } else {
      if (slidesWithinBoundaries(sliderItem, pageContainer)) {
      } else {
        $(slider).removeClass("slider-disabled")
        if (!$(slider).hasClass("component__carousel--broker")) {
          initializeCarousel(slider)
        }
      }
    }
  })
}

export function setupPlusHeader() {
  if (!($("#ph-slider").length > 0)) {
    return
  }

  $("#ph-slider").slick({
    infinite: true,
    autoplay: true,
    fade: true,
    autoplaySpeed: 2000,
    speed: 1000,
    arrows: true,
  })
}

export function setupPhotoCarousel() {
  if (!($(".photocarousel").length > 0)) {
    return
  }

  $(".photocarousel").slick({
    infinite: false,
    autoplay: true,
    speed: 300,
    arrows: false,
    slidesToShow: 3,
    variableWidth: true,
  })
}

export function setupBrokerCarousel() {
  $(".component__carousel--broker").slick({
    adaptiveHeight: true,
  })
}

export function setupNewOrganizationSlider() {
  var organization_slider = $(".organization-slider")

  if (!organization_slider.length) {
    return
  }
  cloneSlides(3, organization_slider, ".slide-div")

  organization_slider.slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "130px",
    autoplay: true,
    draggable: false,
    variableWidth: true,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          arrows: false,
          draggable: true,
        },
      },
    ],
  })

  if ($(".slick-slide").hasClass("slick-active")) {
    $(this).find("a").addClass("show-a")
  }
}

// Cloning slides on breakpoint (slidesToShow equals slides count) to avoid weird margins
function cloneSlides(scrollCount, sliders, targetClass) {
  sliders.each(function () {
    var slider = $(this)
    var slideCount = slider.find(targetClass).length

    if (slideCount <= scrollCount && slideCount > 1) {
      slider.children().clone(true, true).appendTo(slider)
    }
  })
}

function slidesWithinBoundaries(slides, container) {
  var slidesWidth = 0
  var offsetFromMargins = 60

  $(slides).each(function () {
    slidesWidth += parseInt($(this).outerWidth(), 10)
  })

  return slidesWidth < $(container).width() - offsetFromMargins
}

function initializeCarousel(slider) {
  $(slider).slick({
    autoplay: true,
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          centerPadding: "20px",
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 728,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  })
}
