import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import DoublePicker from "../modules/register/register_double_picker"
import {
  calculateTotalPages,
  dateFormatter,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import defaultURLGenerator from "tabulator-tables/src/js/modules/Ajax/defaults/urlGenerator"
import * as XLSX from "xlsx"
import { sanitizeXlsxExport } from "../../../../../shared_assets/app/webpack_assets/modules/sanitize_xlsx_export"

export default class extends ApplicationController {
  static targets = ["fromDate", "toDate"]

  connect() {
    this._initializeTabulator()
    this._intializeDatepicker()
  }

  _initializeTabulator() {
    const pageSize = 25
    const url = this.data.get("api-url")
    const self = this

    const table = new Tabulator("#customer-fees-table", {
      height: "100%",
      layout: "fitDataFill",
      layoutColumnsOnNewData: true,
      addRowPos: "top",
      ajaxURL: url,
      ajaxURLGenerator: function (url, config, params) {
        return defaultURLGenerator(url, config, params)
      },
      ajaxResponse: function (_url, _params, response) {
        self.response = response

        return {
          last_page: calculateTotalPages(response, pageSize),
          data: response.data.map((row) => row.attributes),
        }
      },
      pagination: true,
      paginationMode: "remote",
      paginationSizeSelector: [25, 50, 100],
      paginationSize: pageSize,
      dataSendParams: { size: "page_size" },
      footerElement: this._footer(),
      columns: [
        {
          title: "Datum",
          field: "created_at",
          formatter: dateFormatter,
          frozen: true,
        },
        {
          title: "Produkt",
          field: "customer_service_name",
        },
        {
          title: "Beskrivning",
          field: "description",
        },
        {
          title: "Pris per enhet (ex moms)",
          field: "customer_service_price_per_unit",
          hozAlign: "right",
          formatter: "money",
          formatterParams: {
            precision: false,
            thousand: " ",
          },
        },
        {
          title: "Antal",
          field: "service_units_count",
          hozAlign: "right",
        },
        {
          title: "Summa (ex moms)",
          field: "amount",
          hozAlign: "right",
          formatter: "money",
          formatterParams: {
            precision: false,
            thousand: " ",
          },
        },
        {
          title: "Summa (inkl moms)",
          field: "amount_including_vat",
          hozAlign: "right",
          formatter: "money",
          formatterParams: {
            precision: false,
            thousand: " ",
          },
          frozen: true,
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })

    this.table = table
    window.XLSX = XLSX
  }

  _footer() {
    return `<div class="footer">
    </div>`
  }

  _intializeDatepicker() {
    this.fromDateTarget.value = this.data.get("from")
    this.toDateTarget.value = new Date().toLocaleDateString("sv-se")

    this.doublePicker = new DoublePicker(
      [this.fromDateTarget, this.toDateTarget],
      [],
      [],
      () => this._fetchCustomerFees()
    )
  }

  _fetchCustomerFees() {
    const from = this.fromDateTarget.value
    const to = this.toDateTarget.value
    const url = this.data.get("api-url") + `?from=${from}&to=${to}`

    this.table.setData(url)
  }

  exportTable(event) {
    event.preventDefault()

    let table = this.table
    let page = table.getPage()
    let pageSize = table.getPageSize()

    const organizationName = this.data.get("organizationName")

    table.setPageSize(this.table_rowCount)

    table.setPage(1).then(function () {
      table.download("xlsx", `ABRF Rörligt Arvode ${organizationName}.xlsx`, {
        sheetName: "ABRF Rörligt Arvode",
        documentProcessing: function (workbook) {
          const sheet = workbook.Sheets[workbook.SheetNames[0]]
          sanitizeXlsxExport(sheet, [])
          return workbook
        },
      })

      table.clearData()
      table.setPageSize(pageSize)
      table.setPage(page)
      table.restoreRedraw()
    })
  }
}
